import styled, {css} from "styled-components";

export const CardWrapper = styled.div<{isAbsolute: boolean}>`
  background: rgb(26, 26, 46);
  box-shadow: rgb(26 26 46) 0 3px 78px;
  border-radius: 36px;
  padding: 2rem;

  @media(max-width: 768px) {
    background: transparent;
  }

  ${({isAbsolute}) => isAbsolute && css`
    @media(min-width: 768px) {
      max-width: 50%;
    }
  `}
`

export const CardTitle = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  text-shadow: rgb(255 255 255 / 20%) 0 0 60px;
`

export const CardContent = styled.div`
  padding: 1rem 0;
  text-align: justify;
  display: flex;
`

export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  
  @media(max-width: 768px) {
    flex-direction: column;
  }
`