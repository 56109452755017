import Home from "screens/Home";
import Login from "screens/Login";
import About from "screens/About";
import Media from "screens/Media";
import Events from "screens/Events";
import Social from "screens/Social";

interface RouteType {
  path: string;
  component: any;
  name: string;
  protected: boolean;
}

const routes: RouteType[] = [
  {
    path: "",
    component: Home,
    name: "Home Screen",
    protected: true,
  },
  {
    path: "/about",
    component: About,
    name: "About Screen",
    protected: true,
  },
  {
    path: "/media",
    component: Media,
    name: "About Screen",
    protected: true,
  },
  {
    path: "/events",
    component: Events,
    name: "About Screen",
    protected: true,
  },
  {
    path: "/social",
    component: Social,
    name: "About Screen",
    protected: true,
  },
  {
    path: "/login",
    component: Login,
    name: "Login Screen",
    protected: false,
  },
];

export default routes;
