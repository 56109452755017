import React from 'react';
import youtube from "../../assets/logos/youtube.svg";
import spotify from "../../assets/logos/spotify.svg";
import deezer from "../../assets/logos/deezer.svg";
import facebook from "../../assets/logos/facebook.svg";
import instagram from "../../assets/logos/instagram.svg";
import styled from "styled-components";


export const LogoFooter = styled.div`
  display: flex;    
  padding: 0.5rem;
  width: 100%;
  justify-content: center;
`

export const SocialLogo = styled.a`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Logo = styled.img`
  object-fit: contain;
  cursor: pointer;
  max-width: 50%;
  padding: 1rem;
`

const SocialLogos = () => {
    return (
        <LogoFooter>
            <SocialLogo href="https://www.youtube.com/marcusmausan" target="_blank">
                <Logo src={youtube}/>
            </SocialLogo>
            <SocialLogo href="https://open.spotify.com/artist/1FZktzhDvYpWJlLrp8A3ec?si=9WQ0mlNnQqGMuoXyLXEtAw" target="_blank">
                <Logo src={spotify}/>
            </SocialLogo>
            <SocialLogo href="https://deezer.page.link/kNMctEBeH235ytRZA" target="_blank">
                <Logo src={deezer}/>
            </SocialLogo>
            <SocialLogo href="https://www.facebook.com/MarcusMausanRockBand" target="_blank">
                <Logo src={facebook}/>
            </SocialLogo>
            <SocialLogo href="https://www.instagram.com/marcus_mausan/" target="_blank">
                <Logo src={instagram}/>
            </SocialLogo>
        </LogoFooter>
    );
};

export default SocialLogos;