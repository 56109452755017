export const nlink = [{
    title: 'História',
    path: '/about'
},{
    title: 'Media',
    path: '/media'
}, {
    title: 'Eventos',
    path: '/events'
}, {
    title: 'Social',
    path: '/social'
}]