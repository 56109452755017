import { useEffect } from 'react';
import Header from 'components/header/Header';
import styled from "styled-components";

import {nlink} from "data/home/navbar/nlink";

const Wrapper = styled.div`
  color: white;
  background-image: linear-gradient(to top, #0b0a25 0%, #58588d 100%);
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80vh;
`

const Soon = styled.div`
  position: absolute;
  top: 50%;
  background: rgb(26, 26, 46);
  padding: 1rem;
  box-shadow: rgb(26 26 46) 0 3px 78px;
  border-radius: 25px;
`

interface Props {}

const Social = ({}: Props) => {
    useEffect(() => {}, []);

    return (
        <>
            <Wrapper>
                <Header navTitles={nlink} />
                <Content>
                    <Soon>
                        Em breve mais conteúdo
                    </Soon>
                </Content>
            </Wrapper>
        </>
    );
};

export default Social;
