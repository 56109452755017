import React, {HTMLProps} from 'react';
import styled, {css} from "styled-components";
import {isMobile} from "components/utils/isMobile";

const ButtonWrapper = styled.button<{ isMobile?: boolean}>`
  border: none;
  font-family: inherit;
  padding: .5em;
  cursor: pointer;
  width: 100%;
  background: rgb(230, 121, 0);
  color: white;
  box-shadow: rgb(230 121 0) 0 0 10px;
  
  &:hover {
    box-shadow: rgb(230 121 0) 0 0 30px;
  }

  @media(max-width: 768px) {
    
  }
`

interface ButtonTypes extends Omit<HTMLProps<HTMLButtonElement>, 'size'> {
    children: React.ReactNode;
}

const Button = ({children, onClick}: ButtonTypes) => {
    return (
        <ButtonWrapper onClick={onClick} isMobile={isMobile()}>
            {children}
        </ButtonWrapper>
    );
};

export default Button;