export const youtubeEmbed = [{
    id: 'edneTgrfpNk',
}, {
    id: 'wIPgCYZwJiA',
}, {
    id: 'M9MP5dnfGvE',
}, {
    id: '6Zh8iudwnQQ',
}, {
    id: 'DqTLL2k1BU4',
}, {
    id: 'pBEFIr0SqVg',
}, {
    id: '_VxE6LXVmNM',
}, {
    id: 'fvFDz-ChliU',
}, {
    id: '-Kl0OX694K0',
}, {
    id: 'C21swXwVBRI',
}, {
    id: 'ibMDgWS-keY',
}, {
    id: 'n6XJE_rU7EQ',
}]

