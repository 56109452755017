import React from 'react';
import Typography from "components/typography/Typography";
import Button from "components/button/Button";
import {CardContent, CardFooter, CardTitle, CardWrapper} from "./Card.styles";
import SocialLogos from "../SocialLogos/SocialLogos";

interface CardTypes {
    title?: string,
    description?: string,
    buttonText?: string,
    showSocialIcon?: boolean,
    handleClick?: () => void;
    isAbsolute?: boolean;
}

const Card = ({title, description, buttonText, showSocialIcon = false, handleClick, isAbsolute = false}: CardTypes) => {
    return (
        <CardWrapper isAbsolute={isAbsolute}>
            <CardTitle><Typography variant='h1'>{title}</Typography></CardTitle>
            <CardContent>
                <Typography variant='desc'>
                    {description}
                </Typography>
            </CardContent>
            <CardFooter>
                {handleClick && <Button onClick={handleClick}>
                    <Typography variant='desc'>
                        {buttonText}
                    </Typography>
                </Button>}
                {showSocialIcon && <SocialLogos />}
            </CardFooter>
        </CardWrapper>
    );
};

export default Card;