import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1rem;
  justify-items: center;
`

interface ListProps {
    children: React.ReactNode;
}

const List = ({children}: ListProps) => {
    return (
        <Wrapper>
            {children}
        </Wrapper>
    );
};

export default List;