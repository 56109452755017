import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const TypeH1 = styled.h1`
  font-family: "Gilfroy Medium", "Helvetica Neue", Helvetica, serif;
  line-height: 60px;
  font-size: 3rem;
  margin: 0;
`

const TypeDescription = styled.span`
  font-family: "Gilfroy Medium", "Helvetica Neue", Helvetica, serif;
  line-height: 20px;
  font-size: 1rem;
  margin: 0;
`

const NavLink = styled.a`
  font-family: "Gilfroy Medium", "Helvetica Neue", Helvetica, serif;
  font-size: 1.2rem;
  line-height: 40px;
  margin: 0;
`

interface Props {
    children: React.ReactNode;
    variant: string;
}

const Typography = ({ children, variant }: Props) => {

    if(variant === 'h1') {
        return <TypeH1>{children}</TypeH1>;
    }

    if(variant === 'desc') {
        return <TypeDescription>{children}</TypeDescription>
    }

    if(variant === 'nav') {
        return <NavLink>{children}</NavLink>;
    }

    return <>{children}</>;
};

export default Typography;