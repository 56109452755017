import React from 'react';
import styled, {css} from "styled-components";

import logo from 'assets/images/Logo_Mausan.png';
import {isMobile} from "components/utils/isMobile";
import {useNavigate} from "react-router-dom";
import Typography from "components/typography/Typography";

const Wrapper = styled.div<{ isMobile?: boolean}>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem 0;

  @media(max-width: 768px) {
    flex-direction: column;
  }
`

const LogoContainer = styled.div`
  display: flex;
  padding: .5rem;
  max-width: 100%;
`

const Image = styled.img`
  object-fit: contain;
  width: 218px;
  height: 91px;
  cursor: pointer;
`

const SearchContainer = styled.div`
  display: flex;
  padding: .5rem;
`

const NavBarWrapper = styled.nav`
  padding: 0;
  display: grid;
  place-items: center;
`

const NavBar = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
`

const NavLink = styled.li`
  cursor: pointer;
  background: rgb(26, 26, 46);
  border-radius: 25px;
  
  @media(min-width: 768px) {
    padding: 0 1rem;
  }

  @media(max-width: 768px) {
    background: transparent;
  }
  
  &:hover {
    box-shadow: rgb(26 26 46) 0 3px 58px;
  }
`

interface links {
    title: string,
    path: string
}

interface HeaderTypes {
    navTitles: links[],
}

const Header = ({ navTitles }: HeaderTypes) => {

    const navigate = useNavigate();

    const navigateTo = (pathTitle: string) => navigate(pathTitle)

    return (
        <Wrapper isMobile={isMobile()}>
            <LogoContainer onClick={() => navigateTo('/')}><Image src={logo} /></LogoContainer>
            <SearchContainer></SearchContainer>
            <NavBarWrapper>
                <NavBar>
                    {navTitles.map((data, index) => (
                        <NavLink key={index} onClick={() => navigateTo(data.path)}>
                            <Typography variant='nav'>{data.title}</Typography>
                        </NavLink>
                    ))}
                </NavBar>
            </NavBarWrapper>
        </Wrapper>
    );
};

export default Header;