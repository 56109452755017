import { useEffect } from 'react';
import Header from 'components/header/Header';
import styled from "styled-components";

import {nlink} from "data/home/navbar/nlink";
import {content} from "../data/home/card/content";
import Card from "../components/card/Card";
import SocialLogos from "../components/SocialLogos/SocialLogos";

const Wrapper = styled.div`
  color: white;
  background-image: linear-gradient(to top, #0b0a25 0%, #58588d 100%);
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  
  @media(min-width: 768px) {
    height: 80vh;
  }
`

const Footer = styled.div`
  margin: 0 25rem;
  
  @media(max-width: 768px) {
    margin: 0;
    
    & > * {
      padding: 0;
    }
  }
`


const GridCenter = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1rem;
  justify-items: center;
  grid-template-areas:
    ". a ."
    ". a .";

  @media(max-width: 768px) {
    margin: 0;
  }
`

const GridItem = styled.div`
  display: flex;
  grid-area: a;
`

interface Props {}

const About = ({}: Props) => {
    useEffect(() => {}, []);

    return (
        <>
            <Wrapper>
                <Header navTitles={nlink} />
                <Content>
                    <Card
                        title={content.title}
                        description={content.description}
                    />
                </Content>
                <GridCenter>
                    <GridItem>
                        <SocialLogos />
                    </GridItem>
                </GridCenter>
            </Wrapper>
        </>
    );
};

export default About;
