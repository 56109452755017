import React, { useEffect } from 'react';
import styled from "styled-components";
import Header from 'components/header/Header';
import YoutubeEmbed from "components/Youtube/YoutubeEmbed";
import List from "components/List/List";

import {nlink} from "data/home/navbar/nlink";
import youtube from "../assets/logos/youtube.svg";
import {Logo, SocialLogo} from "../components/SocialLogos/SocialLogos";
import Typography from "../components/typography/Typography";
import Button from "../components/button/Button";
import {youtubeEmbed} from "../data/home/youtube/videos";

const Wrapper = styled.div`
  color: white;
  background-image: linear-gradient(to top, #0b0a25 0%, #58588d 100%);
`

const Content = styled.div`
    
`

const ListWrapper = styled.div`
  & > * {
    @media(max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
`

const ContentTitle = styled.div`
  & > :last-child {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    
    & > img {
      max-width: 5%;
    }
  }

  @media(max-width: 768px) {
    display: none;
  }
`

const GridCenter = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1rem;
  justify-items: center;
  grid-template-areas:
    ". a ."
    ". a .";

  @media(max-width: 768px) {
    margin: 0;
  }
`

const GridItem = styled.div`
  grid-area: a;
`

interface Props {}

const Media = ({}: Props) => {
    useEffect(() => {}, []);

    return (
        <>
            <Wrapper>
                <Header navTitles={nlink} />
                <Content>
                    <ContentTitle>
                        <Typography variant='h1'>Vídeos</Typography>
                    </ContentTitle>
                    <ListWrapper>
                        <List>
                            {youtubeEmbed.map((data, key) => <YoutubeEmbed embedId={data.id} />)}
                        </List>
                    </ListWrapper>
                    <GridCenter>
                        <GridItem>
                            <Button>
                                <Typography variant='desc'>Veja Mais</Typography>
                            </Button>
                        </GridItem>
                    </GridCenter>
                </Content>
            </Wrapper>
        </>
    );
};

export default Media;
