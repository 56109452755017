import {useEffect, useState} from 'react';
import Header from 'components/header/Header';
import styled from "styled-components";

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

import house from 'assets/images/backgroundImages/house.jpeg';
import {isMobile} from "components/utils/isMobile";
import Card from "components/card/Card";
import {useNavigate} from "react-router-dom";
import {nlink} from "data/home/navbar/nlink";
import {content} from "data/home/card/content";
import {CircularProgress} from "@mui/material";
import Center from "../components/utils/Center";

const Wrapper = styled.div`
  color: white;
`

const BackgroundImage = styled.img`
  max-width: 100%;
  position: fixed;
  object-fit: cover;
  overflow: hidden;
  z-index: -1;
  filter: blur(4px);
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: .75rem;

  @media(max-width: 768px) {
    flex-direction: column;
  }
  
`

const LoadingContainer = styled.div`
  display: flex;
  padding: 1rem;
`


const Image = styled.img`
  max-width: 50%;
  margin: 1rem;
  box-shadow: rgb(26 26 46) 0 3px 78px;
  border-radius: 12px;

  @media(max-width: 768px) {
    max-width: 100%;
  }
  
`

interface Props {}

const Home = ({}: Props) => {
  const [imageFromStorage, setImageFromStorage] = useState('');
  const isMobileCheck = isMobile();

  useEffect(() => {}, []);

  const navigate = useNavigate();

  const storage = firebase.storage();
  const files = [ 'house.jpeg'];
  files.map( filename => {
    storage
        .ref( `/assets/${filename}` )
        .getDownloadURL()
        .then( url => {
            if(!imageFromStorage) setImageFromStorage(url);
        });
  });

  const handleButton = () => {
    navigate('/about');
  }

  if (!imageFromStorage)
    return (
        <Center>
          <CircularProgress />
        </Center>
    );

  return (
      <>
      {!isMobileCheck && <BackgroundImage src={imageFromStorage}/>}
      <Wrapper>
        <Header navTitles={nlink} />
        <Content>
          <Image src={imageFromStorage}/>
          <Card
              handleClick={handleButton}
              title={content.title}
              description={content.description}
              buttonText={content.buttonText}
              showSocialIcon
              isAbsolute
          />
        </Content>
      </Wrapper>
    </>
  );
};

export default Home;
